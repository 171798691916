import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 测试页面
  {
    path: "/test",
    name: "test",
    meta: {
      pageTile: "滑动验证",
    },
    component: () => import("@/views/test.vue"),
  },
  // 专区管理=>下载app
  {
    path: "/prefecture/download",
    name: "prefecture/download",
    meta: {
      pageTile: "折扣专区",
    },
    component: () => import("@/views/prefecture/download.vue"),
  },
  // 专区管理=>折扣专区
  {
    path: "/prefecture/discount/index",
    name: "prefecture/discount/index",
    meta: {
      pageTile: "折扣专区",
    },
    component: () => import("@/views/prefecture/discount/index.vue"),
  },
  // 专区管理=>满减专区
  {
    path: "/prefecture/fullSubtract/index",
    name: "prefecture/fullSubtract/index",
    meta: {
      pageTile: "满减专区",
    },
    component: () => import("@/views/prefecture/fullSubtract/index.vue"),
  },
  // 专区管理=>新品推荐
  {
    path: "/prefecture/newRecommend/index",
    name: "prefecture/newRecommend/index",
    meta: {
      pageTile: "新品推荐",
    },
    component: () => import("@/views/prefecture/newRecommend/index.vue"),
  },
  // 专区管理=>优选技师
  {
    path: "/prefecture/excellentTech/index",
    name: "prefecture/excellentTech/index",
    meta: {
      pageTile: "优选技师",
    },
    component: () => import("@/views/prefecture/excellentTech/index.vue"),
  },
  // 舒昕逸家新势力=>分享
  {
    path: "/DM/index",
    name: "DM/index",
    meta: {
      pageTile: "DM单",
    },
    component: () => import("@/views/DM/index.vue"),
  },
  // 滑动登录
  {
    path: "/captcha",
    name: "captcha",
    meta: {
      pageTile: "滑动验证",
    },
    component: () => import("@/views/captcha.vue"),
  },
  // 滑动登录V2(阿里验证)
  {
    path: "/captchaV2",
    name: "captchaV2",
    meta: {
      pageTile: "滑动验证",
    },
    component: () => import("@/views/captchaV2.vue"),
  },
  // 推荐有礼
  // {
  //   path: "/recommend/index",
  //   name: "recommend/index",
  //   meta: {
  //     pageTile: "推荐有礼",
  //   },
  //   component: () => import("@/views/recommend/index.vue"),
  // },
  // 推荐有礼=>注册
  {
    path: "/recommend/register",
    name: "recommend/register",
    meta: {
      pageTile: "推荐有礼",
    },
    component: () => import("@/views/recommend/register.vue"),
  },
  // 推荐有礼=>注册
  {
    path: "/recommend/registerV2",
    name: "recommend/registerV2",
    meta: {
      pageTile: "推荐有礼",
    },
    component: () => import("@/views/recommend/registerV2.vue"),
  },
  // 推荐有礼=>商家入驻协议
  {
    path: "/recommend/protocol",
    name: "recommend/protocol",
    meta: {
      pageTile: "推荐有礼",
    },
    component: () => import("@/views/recommend/protocol.vue"),
  },
  // 推荐有礼=>下载软件
  {
    path: "/recommend/download",
    name: "recommend/download",
    meta: {
      pageTile: "下载舒昕逸家技师端",
    },
    component: () => import("@/views/recommend/download.vue"),
  },
  // 任务中心=>分享
  {
    path: "/taskCenter/share",
    name: "taskCenter/share",
    meta: {
      pageTile: "任务中心",
    },
    component: () => import("@/views/taskCenter/share.vue"),
  },
  // 任务中心=>规则
  {
    path: "/taskCenter/rule",
    name: "taskCenter/rule",
    meta: {
      pageTile: "规则介绍",
    },
    component: () => import("@/views/taskCenter/rule.vue"),
  },
  // 舒昕逸家新势力=>注册
  {
    path: "/newForce/register",
    name: "newForce/register",
    meta: {
      pageTile: "手机号注册",
    },
    component: () => import("@/views/newForce/register.vue"),
  },
  // 舒昕逸家新势力=>验证码
  {
    path: "/newForce/smsCode",
    name: "newForce/smsCode",
    meta: {
      pageTile: "输入验证码",
    },
    component: () => import("@/views/newForce/smsCode.vue"),
  },
  // 舒昕逸家新势力=>分享
  {
    path: "/newForce/share",
    name: "newForce/share",
    meta: {
      pageTile: "舒昕逸家新势力",
    },
    component: () => import("@/views/newForce/share.vue"),
  },
  // 邀请码跳转小程序
  {
    path: "/jumpxcx",
    name: "jumpxcx",
    meta: {
      pageTile: "舒昕逸家",
    },
    component: () => import("@/views/jumpxcx.vue"),
  },
  {
    path: "/activity/lottery",
    name: "lottery",
    meta: {
      pageTile: "万元现金大抽奖",
    },
    component: () => import("@/views/activity/lottery.vue"),
  },
  {
    path: "*",
    name: "404",
    redirect: "/captcha",
    component: () => import("@/views/captcha.vue"),
  },
  {
    path: "/agreement/about",
    name: "about",
    meta: {
      pageTile: "关于舒昕逸家",
    },
    component: () => import("@/views/agreement/about.vue"),
  },
  {
    path: "/agreement/download",
    name: "download",
    meta: {
      pageTile: "下载客户端",
    },
    component: () => import("@/views/agreement/download.vue"),
  },
  {
    path: "/agreement/join",
    name: "join",
    meta: {
      pageTile: "服务商系统使用协议",
    },
    component: () => import("@/views/agreement/join.vue"),
  },
  {
    path: "/agreement/private",
    name: "private",
    meta: {
      pageTile: "平台隐私政策",
    },
    component: () => import("@/views/agreement/private.vue"),
  },
  {
    path: "/agreement/privateTech",
    name: "privateTech",
    meta: {
      pageTile: "平台隐私政策",
    },
    component: () => import("@/views/agreement/privateTech.vue"),
  },
  {
    path: "/agreement/user",
    name: "user",
    meta: {
      pageTile: "用户协议",
    },
    component: () => import("@/views/agreement/user.vue"),
  },
  {
    path: "/agreement/sdk",
    name: "sdk",
    meta: {
      pageTile: "第三方SDK目录",
    },
    component: () => import("@/views/agreement/sdk.vue"),
  },
  {
    path: "/agreement/member",
    name: "member",
    meta: {
      pageTile: "会员服务协议",
    },
    component: () => import("@/views/agreement/member.vue"),
  },
  {
    path: "/agreement/memberRule",
    name: "memberRule",
    meta: {
      pageTile: "会员规则",
    },
    component: () => import("@/views/agreement/memberRule.vue"),
  },
  {
    path: "/agreement/ball",
    name: "ball",
    meta: {
      pageTile: "球球",
    },
    component: () => import("@/views/agreement/ball.vue"),
  },
  {
    path: "/newActivity/newComer",
    name: "newComer",
    meta: {
      pageTile: "全屋保洁",
    },
    component: () => import("@/views/newActivity/newComer.vue"),
  },
  {
    path: "/newActivity/typeActivity",
    name: "typeActivity",
    meta: {
      pageTile: "夏季清爽惠",
    },
    component: () => import("@/views/newActivity/typeActivity.vue"),
  },
  {
    path: "/newActivity/porjectActivity",
    name: "porjectActivity",
    meta: {
      pageTile: "品质抢先看",
    },
    component: () => import("@/views/newActivity/porjectActivity.vue"),
  },
  {
    path: "/newActivity/recruit",
    name: "recruit",
    meta: {
      pageTile: "保洁招募",
    },
    component: () => import("@/views/newActivity/recruit.vue"),
  },
  {
    path: "/newActivity/allService",
    name: "allService",
    meta: {
      pageTile: "项目介绍",
    },
    component: () => import("@/views/newActivity/allService.vue"),
  },
  {
    path: "/agreement/invite",
    name: "invite",
    meta: {
      pageTile: "邀请有礼活动规则",
    },
    component: () => import("@/views/agreement/invite.vue"),
  },
  {
    path: "/agreement/rule",
    name: "rule",
    meta: {
      pageTile: "退改规则",
    },
    component: () => import("@/views/agreement/rule.vue"),
  },
  {
    path: "/newActivity/myInvite",
    name: "myInvite",
    meta: {
      pageTile: "我的邀请",
    },
    component: () => import("@/views/newActivity/myInvite.vue"),
  },
  {
    path: "/newActivity/invite",
    name: "invite",
    meta: {
      pageTile: "邀请有礼",
    },
    component: () => import("@/views/newActivity/invite.vue"),
  },
  {
    path: "/invite",
    name: "inviter",
    meta: {
      pageTile: "邀请下载",
    },
    component: () => import("@/views/invite.vue"),
  },
  {
    path: "/newActivity/poster",
    name: "poster",
    meta: {
      pageTile: "宣传海报",
    },
    component: () => import("@/views/newActivity/poster.vue"),
  },
  {
    path: "/agreement/buyRule",
    name: "buyRule",
    meta: {
      pageTile: "购买协议",
    },
    component: () => import("@/views/agreement/buyRule.vue"),
  },
  {
    path: "/newActivity/singleActitity",
    name: "singleActitity",
    meta: {
      pageTile: "11.11限时抢购",
    },
    component: () => import("@/views/newActivity/singleActitity.vue"),
  },
  {
    path: "/agreement/siteRule",
    name: "siteRule",
    meta: {
      pageTile: "团长协议",
    },
    component: () => import("@/views/agreement/siteRule.vue"),
  },
  {
    path: "/newActivity/newWeb",
    name: "newWeb",
    meta: {
      pageTile: "在线客服",
    },
    component: () => import("@/views/newActivity/newWeb.vue"),
  },
  {
    path: "/newActivity/weakCurrent",
    name: "weakCurrent",
    meta: {
      pageTile: "网络优化",
    },
    component: () => import("@/views/newActivity/weakCurrent.vue"),
  },
  {
    path: "/newActivity/record",
    name: "record",
    meta: {
      pageTile: "空调团购登记",
    },
    component: () => import("@/views/newActivity/record.vue"),
  },
  {
    path: "/newActivity/newInviter",
    name: "newInviter",
    meta: {
      pageTile: "分享有礼",
    },
    component: () => import("@/views/newActivity/newInviter.vue"),
  },
  {
    path: "/newActivity/elevenActivity",
    name: "elevenActivity",
    meta: {
      pageTile: "钜惠双11",
    },
    component: () => import("@/views/newActivity/elevenActivity.vue"),
  },
  {
    path: "/agreement/shareRule",
    name: "shareRule",
    meta: {
      pageTile: "分享下单协议",
    },
    component: () => import("@/views/agreement/shareRule.vue"),
  },
  {
    path: "/xxw/private",
    name: "private",
    meta: {
      pageTile: "隐私政策",
    },
    component: () => import("@/views/xxw/private.vue"),
  },
  {
    path: "/xxw/user",
    name: "user",
    meta: {
      pageTile: "用户协议",
    },
    component: () => import("@/views/xxw/user.vue"),
  },
  {
    path: "/xxw/about",
    name: "about",
    meta: {
      pageTile: "关于我们",
    },
    component: () => import("@/views/xxw/about.vue"),
  },
  {
    path: "/xxw/pulsvip",
    name: "pulsvip",
    meta: {
      pageTile: "会员协议",
    },
    component: () => import("@/views/xxw/pulsvip.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.pageTile;
  next();
});

export default router;
